import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const PolygonUsability = () => {
  return (
    <div className="polygon-usability">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Polygon's Usability</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            It is possible to integrate apps that are compatible with several chains using Polygon,<br/> 
                            an Ethereum sidechain network with a multichain scaling consensus<br/> 
                            methodology.The best features that you can see in polygons are transaction<br/> 
                            processing speed and strong security. Polygon can sustain high transaction<br/> 
                            processing speeds because it uses a consensus mechanism that completes the<br/> 
                            transaction confirmation process in the block. 
                        </p><br/>
                        <p>
                            The proof of stake method is a significant distinction from ethereum which has been<br/> 
                            applied to polygons for a while. When compared to the network, its gas fee is<br/> 
                            incredibly low.Polygon is unmatched in terms of speed and scalability, processing<br/> 
                            up to 65,000 transactions per second and having a block confirmation time of less<br/> 
                            than seconds which is significantly faster than normal.
                        </p><br/>
                        <p>
                            It is up to the scene creators to decide what data belongs on the blockchain and what<br/> 
                            should be kept on a private server.Nonetheless, a transaction on the Ethereum<br/> 
                            network might also entail altering the data that is kept about a token without altering<br/> 
                            who owns it.
                        </p><br/>
                        <p>
                            For instance, Adding multiple parcels to an estate or altering a parcel's description.
                        </p><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/polygon.png' alt='Polygon' title='Polygon' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default PolygonUsability