import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Summary = () => {
  return (
    <div className="summary">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="page-head">
                <h2>Summary</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="page-body">
                <p>
                  SNAPITWORLD  is a virtual reality platform utilizing the Ethereum and polygon<br/>
                  blockchain. Users are able to produce, consume, and profit from applications and<br/> 
                  content. The lack of available land for apps to be developed leads to the creation of<br/> 
                  hubs that draw in visitors and bring in money for content producers. In-world<br/>
                  products and services, as well as land, can be acquired with WSNPT and SNPTW<br/>
                  tokens. 
                </p><br/>
                <p>
                  To further bootstrap the SNAPIT virtual world, SNPTW tokens will be utilized as<br/>
                  incentives for user adoption and content creation. Users can assert ownership of<br/>
                  virtual property using a blockchain-based parcel ledger, which tracks the contents of<br/>
                  land, the value of the money, and the ability to bar others from entering the globe.
                </p><br/>
                <p>
                  This whitepaper lays out technological specifications, economic principles, and the<br/>
                  philosophical basis of SNAPITVERSE.
                </p><br/>

                <div className="image">
                  <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/summary.png' alt='Summary' title='Summmary' fluid style={{width: '500px'}} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default Summary