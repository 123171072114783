import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const AutomatedMarket = () => {
  return (
    <div className="automated-market">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Automated market maker strategy for SNPTW</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                        By placing assets into these pools, an AMM can make money by acting as a provider<br />
                        of liquidity. How individuals can benefit is as follows: Get Trading Commissions:<br /><br/> 
                        The trading fees that the AMM protocol generates are distributed to liquidity<br /> 
                        providers.<br /><br/>
                        Liquidity pool and Automatic market maker formula:<br /><br/>
                        Lets say,<br /><br/>
                        X is the amount of SNAPITWORLD token in the liquidity pool<br /><br/>
                        Assume it as 10000 SNPTW<br /><br/>
                        Y is the amount of the USDT, BNB, matic, token and assume it as 10000 USDT<br /><br/>
                        x*y=k<br /><br/>
                        Where k is constant<br /><br/>
                        For example,<br /><br/> 
                        x=1000<br />
                        y=1000<br /><br/>
                            x*y=k<br />
	                        <strong>(x + ∆x) * (y - ∆y) = k ∆y = y - y * (x + ∆x) / (x)</strong><br /><br/>
	
	                    Simplify it to:<br />
                            <strong>∆y = y - (k / (x + ∆x))</strong><br /><br/>
	
	                    The price of asset x in terms of asset y after the trade can be calculated as the ratio of ∆y to ∆x:<br />
	                    <strong>Price = ∆y / ∆x</strong>
                        </p><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/marker-strategy.png' alt='Marker-strategy' title='Marker-strategy' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AutomatedMarket