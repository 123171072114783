import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const PotentialBusiness = () => {
  return (
    <div className="potential-business">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Potential Business models of Snapit World ecosystem</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                    <ol>
                        <li>Classic model:</li>
                        <p>
                            For every action taken, fees are paid. In contrast to bitcoin and<br/> 
                            Ethereum, fees do not have to be determined by demand; instead, the price<br/> 
                            can be set.
                        </p>
                        <li>Subscription model:</li>
                        <p>
                            After paying for a subscription, users can conduct<br/> 
                            actions without having to pay again; but, in order to stop abuse, these actions<br/> 
                            should have a rate restriction.<br/><br/>
                            For example<br/> 
                            A twitter-like service might be restricted to 50 messages per day.
                        </p>
                        <li>Donation based:</li>
                        <p>
                            Rich contributors may give tokens so that users can receive services at no cost.
                            <ul>
                                <li>Purchase in-game goods such as land</li>
                                <li>Exchange tokens for NFTs</li>
                                <li>Trade goods</li>
                                <li>Use the in-game tax</li>
                            </ul>
                        </p>
                    </ol><br/>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/business.png' alt='Business' title='Business' fluid style={{width: '500px'}} />
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default PotentialBusiness