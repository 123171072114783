import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Vision = () => {
  return (
    <div className="vision">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="page-head">
              <h2>Vision</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="page-body">
              <p>
                Our Vision is to envision a future where the digital and physical worlds seamlessly<br/>
                intertwine, offering boundless opportunities for exploration, connection, and<br/>
                expression. We believe in harnessing the power of technology to create immersive<br/>
                experiences that enhance human interaction. Collaboration, and entertainment use<br/>
                with blockchain technology.
              </p><br/>
              <div className="image">
                <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/vision.png' alt='Vision' title='Vision' fluid style={{width: '500px'}} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Vision