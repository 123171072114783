import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const DecentralizedApplication = () => {
  return (
    <div className="decentralized-application">
         <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Decentralized applications</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            The reader of this text is presumed to be already acquainted with the notion of a<br/> 
                            decentralized application. However, since it is closely related to the platform's<br/> 
                            objective, it makes sense to make clear what we exactly mean. A multi-user<br/> 
                            application that is hosted and offered decentralized is what we mean when we refer<br/> 
                            to it as a "decentralized application." In other words, no one organization should be<br/> 
                            able to dictate how this kind of application operates.
                        </p><br/>
                        <p>The following are possible issues with central control: </p><br/>
                        <ul>
                            <li>
                                Application shutdown;
                            </li>
                            <li>
                                Service denial to specific user categories;
                            </li>
                            <li>
                                User privacy violations for monetization; 
                            </li>
                        </ul><br/>
                        <p>
                            For certain types of applications, such as office suites and file sharing, open source <br/>
                            and peer-to-peer software solved the problem of centralized control; however,<br/> 
                            software that depends on server-hosted databases presents far greater difficulties.<br/> 
                            Arguably, Bitcoin was the first to accomplish this, enabling payment apps free from<br/>
                            the authority of centralized organizations and building a safe decentralized shared<br/> 
                            database of financial transactions.
                        </p><br/>
                        <p>
                            Nevertheless, the "database" of bitcoin is incredibly outdated. More decentralized<br/> 
                            apps can be made viable with an increasingly sophisticated decentralized database,<br/> 
                            perhaps leading to the creation of hitherto unimaginable new types of applications.<br/>
                        </p><br/>
                        <p>
                            The following are advantageous characteristics of decentralized applications:<br/><br/>
                            It should ideally be managed by the user community. It should not be able to be shut down.<br/> 
                            It should be censorship-resistant, meaning that service cannot be refused. It<br/> 
                            should be transparent, allowing users to see what is happening.<br/>
                            We do not anticipate that decentralized apps will include every one of these<br/> 
                            functions. It's possible for certain aspects to contradict one another. A dapp may, for<br/> 
                            instance, allow the majority of users to deny access to a minority; in this scenario,<br/> 
                            the dapp is user-controlled but not censorship-resistant. In reality, developers of<br/> 
                            applications try to strike a fair balance between decentralization and other concerns.
                        </p><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/decentralize.png' alt='Decentralized' title='Decentralized' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default DecentralizedApplication