import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const SnapitToken = () => {
  return (
    <div className="snapit-token">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>What exactly is SNAPIT TOKEN -SNPTW?</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            SNAPIT is a native token that adheres to the Ethereum token standard to maximize<br/> 
                            the development and administration of digital assets. In a single smart contract, it<br/> 
                            enables the combining of fungible and non-fungible tokens, simplifying transactions<br/> 
                            and cutting expenses. With its application metaverse gaming, the SNAPITW coin<br/> 
                            will power the SNAPITWORLD ecosystem.
                        </p>
                        <br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/snapit-token.png' alt='Snapit-token' title='Snapit-token' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default SnapitToken