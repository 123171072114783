import SidebarItem from "../SidebarItem/SidebarItem"
import items from "../../Data/Sidebar.json"
import { RxCross1 } from "react-icons/rx";


export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="text-end d-lg-none d-block">
        <button className="me-2 mobile-toggler-close-button"><RxCross1 /></button>
      </div>
      {items.map((item, index) => <SidebarItem key={index} item={item} />)}
    </div>
  )
}