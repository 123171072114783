import React from "react";
import './Whitepaper.css'
import { Routes, Route } from 'react-router-dom'
import Sidebar from "../../components/Sidebar/Sidebar";
import Summary from '../../components/Summary/Summary'
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre'
import Vision from '../../components/Vision/Vision'
import Mission from '../../components/Mission/Mission'
import ProblemsAndSolution from '../../components/ProblemsAndSolution/ProblemsAndSolution'
import EthereumUsability from '../../components/EthereumUsability/EthereumUsability'
import PolygonUsability from '../../components/PolygonUsability/PolygonUsability'
import DecentralizedApplication from '../../components/DecentralizedApplication/DecentralizedApplication'
import SnapitWorldEcoSystem from '../../components/SnapitWorldEcoSystem/SnapitWorldEcoSystem'
import RoleOfSnapitWorld from '../../components/RoleOfSnapitWorld/RoleOfSnapitWorld'
import PotentialBusiness from '../../components/PotentialBusinessModels/PotentialBusiness'
import WrappedSnapit from '../../components/WrappedSnapit/WrappedSnapit'
import SnapitToken from '../../components/SnapitToken/SnapitToken'
import Usecases from '../../components/Usecases/Usecases'
import Governance from '../../components/Governance/Governance'
import AutomatedMarket from '../../components/AutomatedMarket/AutomatedMarket'
import Tokenomics from '../../components/Tokenomics/Tokenomics'
import WorkFlow from '../../components/WorkFlow/WorkFlow'
import BottomLine from '../../components/BottomLine/BottomLine'
import Disclimer from '../../components/Disclimer/Disclimer'
import { VscThreeBars } from "react-icons/vsc";


const Whitepaper = () => {
  return (
    <div className="whitepaper">
      <button className="mobile-toggler-button"><VscThreeBars /></button>
      <Sidebar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Summary />} />
          <Route path="/Summary" element={<Summary />} />
          <Route path="/WhoWeAre" element={<WhoWeAre />} />
          <Route path="/Vision" element={<Vision />} />
          <Route path="/Mission" element={<Mission />} />
          <Route
            path="/OrganizationalProblemsandsolutions"
            element={<ProblemsAndSolution />}
          />
          <Route path="/Ethereum’sUsability" element={<EthereumUsability />} />
          <Route path="/Polygon’sUsability" element={<PolygonUsability />} />
          <Route
            path="/DecentralizedApplications"
            element={<DecentralizedApplication />}
          />
          <Route
            path="/SNAPITWORLDEcosystem"
            element={<SnapitWorldEcoSystem />}
          />
          <Route path="/TheRoleOfSNAPITWORLD" element={<RoleOfSnapitWorld />} />
          <Route
            path="/PotentialBusinessModelsOfSnapitWorldEcosystem"
            element={<PotentialBusiness />}
          />
          <Route
            path="/WhatIsAWrappedSnapitToken-WSNPT"
            element={<WrappedSnapit />}
          />
          <Route
            path="/WhatExactlyIsSNAPITTOKEN-SNPTW"
            element={<SnapitToken />}
          />
          <Route path="/UsecasesOfSNPTW" element={<Usecases />} />
          <Route path="/GovernanceOfSNPTW" element={<Governance />} />
          <Route
            path="/AutomatedMarketMakerStrategyForSNPTW"
            element={<AutomatedMarket />}
          />
          <Route path="/Tokenomics" element={<Tokenomics />} />
          <Route path="/Roadmap" element={<WorkFlow />} />
          <Route path="/Bottomline" element={<BottomLine />} />
          <Route path="/Disclimer" element={<Disclimer />} />
        </Routes>
      </div>
    </div>
  );
};

export default Whitepaper;
