import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const SnapitWorldEcoSystem = () => {
  return (
    <div className="snapit-world-ecosystem">
         <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>SNAPITWORLD  Ecosystem </h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            SnapitWorld is a revolutionary metaverse ecosystem, aimed to transform your<br/> 
                            experiences with digital advertising, gaming, real estate, healthcare, social media,<br/> 
                            and education. Snapit provides users with unmatched possibilities for discovery,<br/> 
                            engagement, and cooperation by skillfully fusing avant-garde technology with<br/> 
                            immersive virtual environments. 
                        </p><br/>
                        <p>
                            Users of Snapit World can explore a wide variety of experiences from virtual real<br/> 
                            estate investments to gaming expeditions. In one single platform, you can do digital<br/> 
                            advertising, receive medical advice and treatments, and participate in social events.<br/> 
                        </p><br/>
                        <p>
                            With our SNPTW native token, Snapit offers users a secure and effective way to<br/> 
                            conduct transactions, buy digital goods, eCommerce payment services etc within the<br/> 
                            ecosystem, making it the centre of the Snapit world. In SnapitWorld , the token keeps<br/> 
                            everything running smoothly and promotes a thriving economy. 
                        </p><br/>
                        <span>
                            Embark your journey into our SNAPIT WORLD, an infinite virtual environment<br/> 
                            where opportunities seem to be endless. 
                        </span><br/><br/>
                        <div className='image'>
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/flow-chart.png' alt='Flow-chart' title='Flow-chart' fluid />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default SnapitWorldEcoSystem