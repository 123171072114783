import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col } from 'react-bootstrap'

const Disclimer = () => {
  return (
    <div className="disclimer">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="page-head">
              <h4>Are you hitching towards our SNAPIT WORLD ?</h4>
              <h5>Stay tuned for the further updates</h5>
              <h1>DISCLIMER</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="page-body">
              <p>
                This document is purely meant to be instructive. The paper gives a summary of the SNAPITWORLD<br/>
                ecosystem and its potential advantages based on the centralized<br/>
                exchange mentioned above. This document does not constitute an offer to buy tokens<br/>
                or securities or to invest money in them.<br/><br/>
                The information contained in this whitepaper is subject to change at any time and<br/> 
                without notice. Due to the ongoing development of the SNAPITWORLD<br/> 
                environment, the dates and functions included in this paper are indicative and could<br/> 
                change at any time.<br/>
                Financial, legal, or investment advice is not what this whitepaper is meant to offer.<br/> 
                Readers are encouraged to explore ideas with their professional advisors before<br/> 
                deciding on any aspects of the project detailed here.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Disclimer