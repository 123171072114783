import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Tokenomics = () => {
  return (
    <div className="tokenomics">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Tokenomics</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Token details</h4><br/>
                        <p>Token name: SNAPIT</p>
                        <p>Token symbol: SNPTW</p>
                        <p>Total supply: 100 Million</p>
                        <p>Token standard: ERC 1155</p>
                        <p>Decimal: 18</p>
                        <p>Blockchain: Ethereum and Polygon</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Token allocation</h4><br/>
                        <p>Community: 30%</p>
                        <p>Board members: 30%</p>
                        <p>Treasure: 15%</p>
                        <p>Development and marketting: 15%</p>
                        <p>metaverse ecosystem rewards: 5%</p>
                        <p>Liquidity: 5%</p>
                    </div><br/>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/tokenomics.png' alt='Tokenomics' title='Tokenomics' fluid style={{width: '500px'}} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Tokenomics