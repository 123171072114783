import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col,Image } from 'react-bootstrap'

const Mission = () => {
  return (
    <div className="mission">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="page-head">
              <h2>Mission</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="page-body">
              <p>
                Our mission is to build the foundation for the metaverse by developing<br/>
                cutting-edge technologies, platforms, and content that empower users to explore,<br/>
                create and connect in unprecedented ways. We are committed to pushing the<br/>
                boundaries of what is possible in virtual environments and redefining the way<br/>
                people interact with digital spaces.
              </p><br/>
              <div className="image">
                <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/mission.png' alt='Mission' title='Mission' fluid style={{width: '500px'}} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Mission