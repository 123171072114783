import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const BottomLine = () => {
  return (
    <div className="bottom-line">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Bottomline</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            SNAPITWORLD is a full scale solution for all the sectors aimed to experience the<br/> 
                            immersive virtual world. This ecosystem is a one stop solution for all the gamers and<br/> 
                            users who like to experience virtual environments.Snapitworld is used for all<br/> 
                            entertainment purposes and users to receive a real-life experience. 
                        </p><br/>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/bottom-line.png' alt='Bottom-line' title='Bottom-line' fluid style={{width: '500px'}} />
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default BottomLine