import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image} from 'react-bootstrap'

const WhoWeAre = () => {
  return (
    <div className="who-we-are">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="page-head">
              <h2>Who We Are?</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="page-body">
              <p>
                Welcome to SNAPITVERSE virtual technologies.inc, where we are merging the<br/>
                transformative power of blockchain technology with the limitless possibilities of the<br/>
                metaverse.We build a decentralized infrastructure that empowers users, developers,<br/>
                and businesses to create, collaborate, and transact within virtual environments<br />
                securely and transparently. Our company was officially launched in 2022 in<br />
                TURKEY. We have branches in Switzerland, India and Dubai.<br/><br/>
              </p>
              <p>
                We are a dynamic metaverse company offering a diverse range of services across<br/>
                multiple sectors such as gaming , social activities, real estate, education, medical,<br/>
                and digital marketing. Our team aims to revolutionize the virtual landscape with the<br />
                native crypto token and advanced AI based financial system in future. 
              </p><br/>
              <div className="image">
                <Image 
                  src='https://res.cloudinary.com/dqaf5cdew/image/upload/who-we-are.png' 
                  alt='Who we are' 
                  title='Who we are' 
                  fluid style={{width: '500px'}} 
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhoWeAre