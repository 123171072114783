import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const ProblemsAndSolution = () => {
  return (
    <div className="problems-and-solution">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Organizational Problems and Solutions</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            Without a doubt, blockchain innovation has brought about a number of<br />
                            advancements in the world. For example,the gaming industry has expanded quickly<br />
                            and seen revenue growth over the years. However, users of these innovations have<br/>
                            encountered a number of issues that have hindered their participation. These issues<br/>
                            include,
                            <br/><br/>
                        </p>
                        <ul>
                            <li>
                                One of the main obstacles that blockchain developments must overcome is<br/>
                                security. A deficiency in security allows hackers to exploit blockchain<br/>
                                innovation, seize control of projects, and embezzle investors' hard-earned<br/>
                                capital. As a result, everyone who invests in blockchain initiatives needs to<br/>
                                know that its security; otherwise, their money is lost.
                            </li>
                            <li>
                            	NFTs have over the years served as an object of admiration and bragging<br/>
                                rights for owners, and periods after acquiring these sets of NFTs,they keep<br/>
                                losing the value, and after a while, they become irrelevant and selling them<br/>
                                off becomes a problem to the holders.
                            </li>
                            <li>
                            	Virtual worlds are the most immersive existence in isolated settings. A great<br/>
                                deal of development is required to make such settings more accessible and to<br/>
                                lessen the difficulty of transferring apps between platforms.
                            </li>
                        </ul><br/>                                               
                        <p>
                            With the above issues to be solved , we introduced the SnapitWorld ecosystem which<br/> 
                            has a diverse range of solutions for all the sectors. Especially gaming , virtual offices,<br/>
                            education, healthcare etc. 
                        </p><br/>
                        <p>
                            Snapit games are live streamed , users get to enjoy games and get the real live<br/> 
                            experience of all games in the SnapitWorld platform.This implies  that the game are<br/>
                            not algorithmic based and are not designed with a specific outcomes. Snapitworld<br/>
                            eradicate all forms of manipulation which is a norm in most gaming virtual<br/>
                            platforms.
                        </p><br/>
                        <p>
                            Snapitworld metaverse provides users with luxurious designs , users can make a<br/>
                            grand entry to Snapitworld using cars and sophisticated yachts. Snapitworld<br/>
                            ecosystem is built for luxury and users get experience right in the Snapitworld<br/>
                            metaverse. This has eliminated the cost and risk associated with going to these<br/>
                            locations.
                        </p><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/problem.png' alt='Problem' title='Problem' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ProblemsAndSolution