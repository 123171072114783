import React from 'react'
import './App.css'
import NavBar from './components/NavBar/NavBar'
import Whitepaper from './pages/Whitepaper/Whitepaper'
import $ from "jquery"
import { useState, useEffect } from "react";

const App = () => {

  useEffect(() => {
    $(document).ready(function () {
      $(".mobile-toggler-button").click(function () {
        $(".sidebar").css("width", "300px");
      });
      $(".mobile-toggler-close-button").click(function () {
        $(".sidebar").css("width", "0px");
      });
    });
  });
  return (
    <div className="app">
      <NavBar />
      <Whitepaper />
    </div>
  )
}

export default App