import React from "react";
import "./NavBar.css";
import { Image, Button, FormControl } from "react-bootstrap";

const NavBar = () => {
  return (
    <div className="nav-bar">
      <div className="logo">
        <a href="/">
          <Image src="https://res.cloudinary.com/dqaf5cdew/image/upload/logo.png" alt="Header-logo" title="Header-logo" fluid />
        </a>
      </div>
      <div className="input-section">
        <Button variant="primary">
          <i class="bi bi-search" />
        </Button>
        <FormControl type="text" placeholder="Search" />
      </div>
    </div>
  );
};

export default NavBar;
