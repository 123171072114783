import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const WrappedSnapit = () => {
  return (
    <div className="wrapped-snapit">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>What is a Wrapped Snapit token-WSNPT?</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            WSNPT token is a digital asset that symbolizes a distinct coin or asset using a<br/> 
                            different blockchain like ethereum , polygon etc. It encapsulates the original<br/> 
                            cryptocurrency by locking it into a smart contract and creating tokens on a different<br/> 
                            blockchain in line with that. In doing so, the value and characteristics of the<br/> 
                            underlying assets are preserved and the token can be used within the ecosystem of<br/> 
                            that particular blockchain.<br/><br/>
                            WSNPT tokens are frequently used to enable asset transfers and promote<br/> 
                            interoperability amongst various blockchain networks.They are frequently used to<br/> 
                            offer liquidity and access to assets from several blockchains in DeFi (decentralized<br/> 
                            finance) and DEX (decentralized exchange) applications.
                        </p><br/>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/wrapped.png' alt='Wrapped' title='Wrapped' fluid style={{width: '500px'}} />
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default WrappedSnapit