import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const Governance = () => {
  return (
    <div className="governance">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Governance of SNPTW</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            SnapitWorld supports different governance structures on the system and application<br/> 
                            levels.
                        </p>
                        <ul>
                            <li>SnapiWorld system governance</li><br/>
                            <p>Problems addressed by system-level governance include the following:</p>
                            <ol>
                                <li>
                                    System updates, or revisions to the blockchain structures, regulations, and<br/>
                                    other aspects of the system.
                                </li>
                                <li>
                                    Adjusting for real-world economic conditions when it comes to parameters like<br/> 
                                    the cost of operating a Dapp.
                                </li>
                                <li>Adding additional participants to the system.</li>
                                <li>Elimination of miscreants.</li>
                            </ol>
                        </ul><br/>
                        <p>
                            Clearly, the system's governance needs to be decentralized; one organization cannot<br/> 
                            be in charge of it. We think that the ideal people to carry out governance<br/> 
                            responsibilities are providers:
                        </p>
                        <ul className='ul'>
                            <li>
                                They are driven to maintain SnapitWorld engaging for both users and<br/> 
                                application developers.
                            </li>
                            <li>
                                They are qualified to evaluate the suggestions. Providers' revenues and<br/>
                                profitability will be impacted by a poor governance choice
                            </li>
                            <li>
                                Therefore, in order to pass a governance proposal, ⅔ of providers must vote<br/> 
                                in support of it.
                            </li>
                        </ul><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/governance.png' alt='Governance' title='Governance' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Governance