import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const WorkFlow = () => {
  return (
    <div className="workflow">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>WorkFlow Of SnapitWorld</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 1:</h4>
                        <p>Planning the ecosystem</p>
                        <p>Core team building</p>
                        <p>Concept of the metaverse</p>
                        <p>First version of the website</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 2:</h4>
                        <p>Enhancing the website</p>
                        <p>Initial Token launch ( WSNPT ) - Wrapped Snapit token</p>
                        <p>Seed sale</p>
                        <p>Staking for WSNPT</p>
                        <p>Initiating the community building through marketing</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 3:</h4>
                        <p>Launch for the shooting game</p>
                        <p>Metaverse Environment design</p>
                        <p>Influencer on board</p>
                        <p>Whitepaper and tokenomics</p>
                        <p>Airdrop for WSNPT holders </p>
                        <p>Launching SNPTW ( snapit world token )</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 4:</h4>
                        <p>Prototype Metaverse gameplay for community</p>
                        <p>Launch Swapping WSNPT to SNPTW</p>
                        <p>Metaverse Map designing and building</p>
                        <p>Metaverse land NFT initiating</p>
                        <p>Burning WSNPT by the core team</p>
                        <p>Enhancing the marketing team</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 5:</h4>
                        <p>Metaverse governance launch</p>
                        <p>NFT launch on opensea</p>
                        <p>Snapit world Launch </p>
                        <p>Staking for SNPTW</p>
                        <p>Launching SNPTW in DEX</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 6:</h4>
                        <p>Partnership with top vendors </p>
                        <p>Hospitals, Shops and advertisement partnership in Metaverse</p>
                        <p>Launching NFT land renting</p>
                        <p>Launching SNPTW in Tier 1 Centralized exchanges</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <h4>Phase 7:</h4>
                        <p>Enhancing the Metaverse gameplay</p>
                        <p>Creating Game inside the metaverse</p>
                        <p>Launching Snapit Trade center</p>
                        <p>Launching Snapit Mall</p>
                    </div><br/>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/roadmap.png' alt='Roadmap' title='Roadmap' fluid style={{width: '500px'}} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default WorkFlow