import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col,Image } from 'react-bootstrap'

const RoleOfSnapitWorld = () => {
  return (
    <div className="role">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>The role of SNAPITWORLD</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                    <ul>
                        <li>Transport</li>
                        <p>
                            Once the application is finalized, it will be hosted on numerous nodes and become<br/> 
                            immutable due to blockchain consensus. We think transparency will be the primary<br/> 
                            feature in a lot of applications.SnapitWorld is an impartial technology provider; it<br/> 
                            does not impose decentralization on its own. Transparency is frequently already a<br/> 
                            great improvement over the current situation.
                        </p>
                        <li>Privacy</li>
                        <p>
                            Privacy is an intricate subject. Decentralized application data is usually accessible<br/> 
                            to the public, so the application design must take it into consideration. For instance,<br/> 
                            it might make use of zero-knowledge proofs, hashing, pseudonymous identities, and<br/> 
                            other cryptographic techniques.<br/>
                            We think that this strategy outperforms the conventional strategy that depends on <br/>
                            the confidentiality and confidence of application providers. If a provider's security <br/>
                            is compromised in a centralized approach, privacy is completely endangered.<br/> 
                            According to our approach, the data cannot be corrupted because it was initially <br/>
                            made public.In the future, SnapitWorld intends to provide privacy-enhancing <br/>
                            capabilities (for usage in dapps).
                        </p>
                        <li>Highly available</li>
                        <p>
                            SnapitWorld is built to survive node failures. Its tolerance for failures is a parameter<br/> 
                            that can be changed. It can tolerate a single node failure when there are four<br/> 
                            minimum nodes. More nodes can be employed if greater availability is required.<br/>
                        </p>
                        <li>Decentralization quality</li>
                        <p>
                            SnapitWorld will permit applications to be hosted regardless of their degree of<br/> 
                            decentralization since it wants to function as an impartial technical platform rather<br/> 
                            than a moral authority.<br/>
                            On the other hand, we think that decentralization is crucial and that consumers<br/> 
                            should be aware of the characteristics of the program they are using. We intend to<br/> 
                            create rules and evaluation standards as a result. Applications will be ranked<br/> 
                            according to these criteria by independent companies. Additionally, we urge users<br/> 
                            to request an impartial code audit.
                        </p>
                    </ul><br/>
                    <div className="image">
                        <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/role.png' alt='Role' title='Role' fluid style={{width: '500px'}} />
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default RoleOfSnapitWorld