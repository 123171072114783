import React from 'react'
import '../../pages/Whitepaper/Whitepaper.css'
import { Container, Row, Col, Image } from 'react-bootstrap'

const EthereumUsability = () => {
  return (
    <div className="ethereum-usability">
        <Container>
            <Row>
                <Col lg={12}>
                    <div className="page-head">
                        <h2>Ethereum's Usability</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="page-body">
                        <p>
                            To track who owns digital assets and other marketable goods that a 3D scene can<br/> 
                            read and respond to, we employ the Ethereum blockchain.Scene status, player<br/>
                            position, and other data that must update in real time when a player interacts with a<br/> 
                            scene are not maintained on the blockchain; instead, they are either kept locally on<br/> 
                            each player's computer or on a private server that is held by the scene owner.<br/> 
                        </p><br/>
                        <p>
                            It is up to the scene creators to decide what data belongs on the blockchain and what<br/> 
                            should be kept on a private server.Nonetheless, a transaction on the Ethereum<br/> 
                            network might also entail altering the data that is kept about a token without altering<br/> 
                            who owns it.
                        </p><br/>
                        <p>
                            For instance, Adding multiple parcels to an estate or altering a parcel's description.
                        </p><br/>
                        <div className="image">
                            <Image src='https://res.cloudinary.com/dqaf5cdew/image/upload/ethereum.png' alt='Ethereum' title='Ethereum' fluid style={{width: '500px'}} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default EthereumUsability